<template>
  <Start></Start>
</template>

<script>



  import Start from "@/views/Start";

  export default {
    components: {
      Start,



    }
  }
</script>
