import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark:false,
        themes: {
            light: {
                primary: '#cd0a0a',
                secondary: '#009bdc',
                accent: '#3e3d3d',
                error: '#FF5252',
                info: '#07C730',
                success: '#607D8B',
                warning: '#CF232A',
            },
            dark: {
                primary: '#cd0a0a',
                secondary: '#009bdc',
                accent: '#3e3d3d',
                error: '#FF5252',
                info: '#07C730',
                success: '#607D8B',
                warning: '#CF232A',
            },
        },
    },
})
